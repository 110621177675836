const formatMoney = (amount: number) => {
  return (amount / 100).toLocaleString('de-AT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })
}

const formatNumber = (n: number) => {
  return n.toLocaleString('de-AT', {
    maximumFractionDigits: 2
  })
}

const round = (n: number) => {
  return Math.round((n + Number.EPSILON) * 100) / 100
}

export { formatMoney, formatNumber, round }
