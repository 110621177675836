








































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { signIn, signOut, userChanged } from '@/services/Identity'
import Dropdown from '@/components/Dropdown.vue'
import ImagePlaceholder from '@/components/ImagePlaceholder.vue'
import { Identity } from '@/models/Identity'
import { NavigationItem } from '@/models/NavigationItem'
import { User } from '@/models/User'
import { firestore } from 'firebase/app'
import { UserMessageConverter } from '@/models/converters/UserMessageConverter'
import { UserMessage } from '@/models/UserMessage'
import { getFromNow } from '@/services/DateService'
import { mixins } from 'vue-class-component'
import UserMixin from '@/mixins/UserMixin.vue'
import Dialog from '@/components/Dialog.vue'
import Button from '@/components/Button.vue'

@Component({
  components: {
    Button,
    Dialog,
    Dropdown,
    ImagePlaceholder
  }
})
export default class Navigation extends mixins(UserMixin) {
  navbarOpen: boolean = false
  dropdownOpen: boolean = false
  messageDropdownOpen: boolean = false
  messageDialogVisible: boolean = false
  currentMessage: UserMessage = null

  get items(): NavigationItem[] {
    const role = this.user ? this.user.role : 'customer'
    const homeItem = {
      text: 'Home',
      name: 'landing',
      isVisible: () => true
    }
    const mainItem = {
      text: 'meinSCHWEIN',
      name: 'home',
      isVisible: () => !!this.mainStore.identity
    }
    const publicItems = [
      { text: 'Betriebe', name: 'farms', isVisible: () => true },
      { text: 'Produkte', name: 'products', isVisible: () => true },
      { text: 'Info', name: 'info', isVisible: () => true },
      {
        text: 'FAQ',
        name: 'faq',
        isVisible: () => true
      }
    ]

    switch (role) {
      case 'customer': {
        return [homeItem, mainItem, ...publicItems]
      }
      case 'admin': {
        return [
          homeItem,
          {
            text: 'Admin',
            name: 'admin',
            isVisible: () => true
          },
          mainItem,
          ...publicItems
        ]
      }
      case 'farmer': {
        return [
          homeItem,
          {
            text: 'Admin',
            name: 'admin/farm',
            isVisible: () => true
          },
          mainItem,
          ...publicItems
        ]
      }
    }
  }
  loading: boolean = false
  messages: UserMessage[] = []
  unsubscriber: () => void

  get identity(): Identity {
    return this.mainStore.identity
  }

  get cartItems(): number {
    return this.mainStore.cart.items.length
  }

  get unreadMessages(): number {
    return this.messages.filter((m) => !m.read).length
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen
  }

  async created() {
    await this.mainStore.initializeWait()

    if (this.user) {
      this.unsubscriber = firestore()
        .collection('users')
        .doc(this.user.id)
        .collection('messages')
        .withConverter(new UserMessageConverter())
        .orderBy('created_at', 'desc')
        .limit(5)
        .onSnapshot(
          (snapshot) => (this.messages = snapshot.docs.map((doc) => doc.data()))
        )
    }
  }

  async toggleMessageDropdown(state?: boolean) {
    if (state == null) {
      state = !this.messageDropdownOpen
    }

    const stateWillChange = this.messageDropdownOpen !== state
    this.messageDropdownOpen = state

    if (!this.messageDropdownOpen && stateWillChange) {
      const promises = []
      for (const message of this.messages.filter((m) => !m.read)) {
        message.read = true
        promises.push(
          message.reference
            .withConverter(new UserMessageConverter())
            .set(message, { merge: true })
        )
      }

      await Promise.all(promises)
    }
  }

  getFromNow(date: Date) {
    return getFromNow(date)
  }

  showMessageDetail(message: UserMessage) {
    this.currentMessage = message
    this.messageDialogVisible = true
    this.toggleMessageDropdown(false)
  }

  async signOut() {
    await signOut()
    this.navbarOpen = false
  }

  destroyed() {
    if (this.unsubscriber) {
      this.unsubscriber()
    }
  }
}
