import Vue from 'vue'
import Router from 'vue-router'
import Landing from '@/views/Landing.vue'
import { mainStore } from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing,
      meta: {
        title: 'Die Schweinemast der Zukunft'
      }
    },
    {
      path: '/meinschwein',
      name: 'home',
      component: () =>
        import(/* webpackChunkName: "home" */ './views/Home.vue'),
      meta: {
        requiresAuthenticated: true,
        title: 'Übersicht'
      }
    },
    {
      path: '/registrieren',
      name: 'sign-up',
      component: () =>
        import(/* webpackChunkName: "sign-up" */ './views/SignUp.vue'),
      meta: {
        requiresAnonymous: true,
        title: 'Registrierung'
      }
    },
    {
      path: '/passwort-vergessen',
      name: 'forgot-password',
      component: () =>
        import(
          /* webpackChunkName: "forgot-password" */ './views/ForgotPassword.vue'
        ),
      meta: {
        requiresAnonymous: true,
        title: 'Passwort vergessen'
      }
    },
    {
      path: '/anmelden',
      name: 'sign-in',
      component: () =>
        import(/* webpackChunkName: "sign-in" */ './views/SignIn.vue'),
      meta: {
        requiresAnonymous: true,
        title: 'Anmelden'
      }
    },
    {
      path: '/konto',
      name: 'account',
      component: () =>
        import(/* webpackChunkName: "account" */ './views/Account.vue'),
      meta: {
        requiresAuthenticated: true,
        title: 'Mein Konto'
      }
    },
    {
      path: '/bestellungen',
      name: 'orders',
      component: () =>
        import(/* webpackChunkName: "orders" */ './views/Orders.vue'),
      meta: {
        requiresAuthenticated: true,
        title: 'Meine Bestellungen'
      }
    },
    {
      path: '/betriebe',
      name: 'farms',
      component: () =>
        import(/* webpackChunkName: "farms" */ './views/Farms.vue'),
      meta: {
        requiresAuthenticated: false,
        title: 'Betriebe'
      }
    },
    {
      path: '/betriebe/:slug',
      name: 'farm',
      component: () =>
        import(/* webpackChunkName: "farm-detail" */ './views/FarmDetail.vue'),
      meta: {
        generateTitle: false
      }
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue'),
      meta: {
        title: 'FAQ'
      }
    },
    {
      path: '/produkte',
      name: 'products',
      component: () =>
        import(/* webpackChunkName: "products" */ './views/Products.vue'),
      meta: {
        title: 'Produkte'
      }
    },
    {
      path: '/produkte/:slug',
      name: 'product',
      component: () =>
        import(/* webpackChunkName: "product" */ './views/Product.vue'),
      meta: {
        generateTitle: false
      }
    },
    {
      path: '/info',
      name: 'info',
      component: () =>
        import(/* webpackChunkName: "info" */ './views/Info.vue'),
      meta: {
        title: 'Info'
      }
    },
    {
      path: '/warenkorb',
      name: 'cart',
      component: () =>
        import(/* webpackChunkName: "cart" */ './views/Cart.vue'),
      meta: {
        title: 'Warenkorb'
      }
    },
    {
      path: '/bestellung',
      name: 'order',
      component: () =>
        import(/* webpackChunkName: "order" */ './views/Order.vue'),
      meta: {
        requiresAuthenticated: true,
        title: 'Bestellung'
      }
    },
    {
      path: '/bestellungen/:orderId',
      name: 'order-detail',
      component: () =>
        import(
          /* webpackChunkName: "order-detail" */ './views/OrderDetail.vue'
        ),
      meta: {
        requiresAuthenticated: true,
        title: 'Bestelldetails'
      }
    },
    {
      path: '/agb',
      name: 'terms-of-use',
      component: () =>
        import(/* webpackChunkName: "terms-of-use" */ './views/TermsOfUse.vue'),
      meta: {
        title: 'AGB'
      }
    },
    {
      path: '/beitraege/:articleId',
      name: 'article',
      component: () =>
        import(/* webpackChunkName: "article" */ './views/Article.vue'),
      meta: {
        generateTitle: false
      }
    },
    {
      path: '/presse',
      name: 'press',
      component: () =>
        import(/* webpackChunkName: "article" */ './views/Press.vue')
    },
    {
      path: '/impressum',
      name: 'imprint',
      component: () =>
        import(/* webpackChunkName: "imprint" */ './views/Imprint.vue'),
      meta: {
        title: 'Impressum'
      }
    },
    {
      path: '/datenschutz',
      name: 'privacy',
      component: () =>
        import(/* webpackChunkName: "privacy" */ './views/Privacy.vue'),
      meta: {
        title: 'Datenschutz'
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: () =>
        import(/* webpackChunkName: "admin" */ './views/Admin.vue'),
      meta: {
        requiresAuthenticated: true,
        title: 'Administration'
      },
      redirect: '/admin/betrieb/aktivitaeten',
      children: [
        {
          path: 'betrieb',
          name: 'admin/farm',
          component: () =>
            import(
              /* webpackChunkName: "admin/farm" */ './views/AdminFarm.vue'
            ),
          meta: {
            title: 'Betrieb'
          },
          redirect: '/admin/betrieb/aktivitaeten',
          children: [
            {
              path: 'kameras',
              name: 'admin/farm/cameras',
              component: () =>
                import(
                  /* webpackChunkName: "admin/farm/cameras" */ './views/AdminFarmCameras.vue'
                ),
              meta: {
                title: 'Kameras'
              }
            },
            {
              path: 'kommunikation',
              name: 'admin/farm/communication',
              component: () =>
                import(
                  /* webpackChunkName: "admin/farm/communication" */ './views/AdminFarmCommunication.vue'
                ),
              meta: {
                title: 'Kommunikation'
              }
            },
            {
              path: 'zusatzprodukte',
              name: 'admin/farm/items',
              component: () =>
                import(
                  /* webpackChunkName: "admin/farm/items" */ './views/AdminFarmItems.vue'
                ),
              meta: {
                title: 'Zusatzprodukte'
              }
            },
            {
              path: 'aktivitaeten',
              name: 'admin/farm/activities',
              component: () =>
                import(
                  /* webpackChunkName: "admin/farm/activities" */ './views/AdminFarmActivities.vue'
                ),
              meta: {
                title: 'Aktivitäten'
              }
            },
            {
              path: 'zusatzprodukte/:farmId/:itemId',
              name: 'admin/farm/items-detail',
              component: () =>
                import(
                  /* webpackChunkName: "admin/farm/items-detail" */ './views/AdminFarmItemDetail.vue'
                )
            }
          ]
        },
        {
          path: 'bestellungen',
          name: 'admin/orders',
          component: () =>
            import(
              /* webpackChunkName: "admin/orders" */ './views/AdminOrders.vue'
            ),
          meta: {
            title: 'Bestellungen'
          }
        },
        {
          path: 'bestellungen/:userId/:orderId',
          name: 'admin/orders-detail',
          component: () =>
            import(
              /* webpackChunkName: "admin/orders-detail" */ './views/AdminOrderDetail.vue'
            ),
          meta: {
            title: 'Bestelldetails'
          }
        },
        {
          path: 'benutzer',
          name: 'admin/users',
          component: () =>
            import(
              /* webpackChunkName: "admin/users" */ './views/AdminUsers.vue'
            ),
          meta: {
            title: 'Benutzer'
          }
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  const initPromise = mainStore.initializeWait()

  if (to.matched.some((m) => m.meta.requiresAuthenticated)) {
    await initPromise

    if (!mainStore.identity) {
      next({ name: 'sign-in', query: { target: to.name } })
    } else {
      next()
    }
  } else if (to.matched.some((m) => m.meta.requiresAnonymous)) {
    await initPromise

    if (mainStore.identity && !to.query.reason) {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    next()
  }

  if (
    to.matched.some(
      (m) => m.meta.generateTitle != null && !m.meta.generateTitle
    )
  ) {
    return
  }

  document.title = [
    ...to.matched
      .slice()
      .reverse()
      .filter((m) => !!m.meta.title)
      .map((m) => m.meta.title),
    'meinSCHWEIN'
  ].join(' – ')
})

export default router
