





























import UserMixin from '@/mixins/UserMixin.vue'
import moment from 'moment'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class PromotionBanner extends mixins(UserMixin) {
  get showSignupCouponHint(): boolean {
    return moment().isSameOrBefore(moment('2021-11-30'), 'date')
  }
}
