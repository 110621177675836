type UserRole = 'customer' | 'farmer' | 'admin'
type ProductType = 'pig' | 'credits' | 'coupon' | 'cutting'
type FarmItemType = 'feed' | 'extra'
type BasicFeedingType = 'lean' | 'medium' | 'fat'
type UserOrderState = 'created' | 'fulfilled'
type UserOrderItemState = 'pending' | 'fulfilled'
type NotificationType = 'success' | 'error'
type MeatCutProcessingOption = 'whole' | 'sliced'
type MeatCutUnit = 'kg' | 'pieces'
type MeatCutType = 'default' | 'rough' | 'fine'
type CuttingVariant = 'whole' | 'gastronomy' | 'fine' | 'fine_vacuum_packed'
type DeliveryMethod = 'pickup' | 'delivery'
type MessageScope = 'all_users' | 'all_customers' | 'customers_in_period'
interface Position {
  lat: number
  long: number
}

const mapUserRole = (userRole: UserRole) => {
  switch (userRole) {
    case 'customer': {
      return 'Kunde'
    }
    case 'farmer': {
      return 'Landwirt'
    }
    case 'admin': {
      return 'Admin'
    }
  }
}

const mapProductType = (productType: ProductType) => {
  switch (productType) {
    case 'pig': {
      return 'Schwein'
    }
    case 'credits': {
      return 'Guthaben'
    }
    case 'coupon': {
      return 'Gutschein'
    }
  }
}

const mapFarmItemType = (farmItemType: FarmItemType) => {
  switch (farmItemType) {
    case 'feed': {
      return 'Zusatzfutter'
    }
    case 'extra': {
      return 'Extra'
    }
  }
}

const mapBasicFeedingType = (basicFeedingType: BasicFeedingType) => {
  switch (basicFeedingType) {
    case 'lean': {
      return 'Mager'
    }
    case 'medium': {
      return 'Durchzogen'
    }
    case 'fat': {
      return 'Fett'
    }
  }
}

const mapOrderState = (state: UserOrderState) => {
  switch (state) {
    case 'created': {
      return 'Offen'
    }
    case 'fulfilled': {
      return 'Durchgeführt'
    }
  }
}

const mapCuttingVariant = (variant: CuttingVariant) => {
  switch (variant) {
    case 'whole': {
      return 'Im Ganzen'
    }
    case 'gastronomy': {
      return 'Gastro'
    }
    case 'fine': {
      return 'Fein'
    }
    case 'fine_vacuum_packed': {
      return 'Fein (vakuumverpackt)'
    }
  }
}

const mapDeliveryMethod = (type: DeliveryMethod) => {
  switch (type) {
    case 'pickup': {
      return 'Selbstabholung'
    }
    case 'delivery': {
      return 'Zustellung'
    }
  }
}

const mapMessageScope = (scope: MessageScope) => {
  switch (scope) {
    case 'all_users': {
      return 'Alle registrierten Benutzer'
    }
    case 'all_customers': {
      return 'Alle Kunden'
    }
    case 'customers_in_period': {
      return 'Kunden im Einstellzeitraum'
    }
  }
}

export {
  UserRole,
  ProductType,
  FarmItemType,
  BasicFeedingType,
  UserOrderState,
  UserOrderItemState,
  NotificationType,
  CuttingVariant,
  MeatCutProcessingOption,
  MeatCutUnit,
  MeatCutType,
  DeliveryMethod,
  MessageScope,
  Position,
  mapUserRole,
  mapProductType,
  mapFarmItemType,
  mapBasicFeedingType,
  mapOrderState,
  mapDeliveryMethod,
  mapMessageScope,
  mapCuttingVariant
}
