import { FarmPeriodAssignment } from '@/models/FarmPeriodAssignment'
import { User } from '@/models/User'
import { Farm } from '@/models/Farm'

export class UserConverter
  implements firebase.firestore.FirestoreDataConverter<User> {
  toFirestore(user: User): firebase.firestore.DocumentData {
    return {
      name: user.name,
      email: user.email,
      photo_url: user.photoUrl ?? null,
      phone: user.phone ?? null,
      messaging_token: user.messagingToken ?? null
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const user = new User(snapshot.ref)
    user.name = data.name
    user.email = data.email
    user.role = data.role
    user.photoUrl = data.photo_url
    user.phone = data.phone
    user.credits = data.credits ?? 0
    user.messagingToken = data.messaging_token
    user.meta = data.meta
    if (data.assignments) {
      user.assignments = data.assignments.map(
        (ass: firebase.firestore.DocumentReference) =>
          new FarmPeriodAssignment(ass)
      )
    } else {
      user.assignments = []
    }
    if (user.role === 'farmer') {
      user.farm = new Farm(data.farm)
    }

    return user
  }
}
