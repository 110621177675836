import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store, mainStore } from './store'

import firebase, { firestore } from 'firebase/app'

import 'firebase/analytics'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/messaging'
import { userChanged, getUser } from '@/services/Identity'
import { Identity } from '@/models/Identity'
import { UserConverter } from '@/models/converters/UserConverter'
import { showNotification } from '@/services/NotificationService'
import { User } from '@/models/User'
import { formatMoney, formatNumber } from '@/services/NumberService'
import { formatDate, formatDateTime } from '@/services/DateService'
import {
  BasicFeedingType,
  CuttingVariant,
  DeliveryMethod,
  FarmItemType,
  mapBasicFeedingType,
  mapCuttingVariant,
  mapDeliveryMethod,
  mapFarmItemType,
  mapOrderState,
  mapUserRole,
  UserOrderState,
  UserRole
} from './models/Types'
import { FarmConverter } from './models/converters/FarmConverter'

Vue.config.productionTip = false

Vue.filter('number', (n: number) => {
  return formatNumber(n)
})

Vue.filter('currency', (amount: number) => {
  return formatMoney(amount)
})

Vue.filter('date', (date: Date, format?: string) => {
  return formatDate(date, format)
})

Vue.filter('datetime', (date: Date) => {
  return formatDateTime(date)
})

// Types
Vue.filter('orderState', (state: UserOrderState) => {
  return mapOrderState(state)
})
Vue.filter('basicFeeding', (feeding: BasicFeedingType) => {
  return mapBasicFeedingType(feeding)
})
Vue.filter('cuttingVariant', (variant: CuttingVariant) => {
  return mapCuttingVariant(variant)
})
Vue.filter('userRole', (role: UserRole) => {
  return mapUserRole(role)
})
Vue.filter('farmItemType', (type: FarmItemType) => {
  return mapFarmItemType(type)
})
Vue.filter('deliveryMethod', (method: DeliveryMethod) => {
  return mapDeliveryMethod(method)
})

new Vue({
  router,
  store,
  render: (h) => h(App),
  created: async () => {
    const firebaseConfig =
      window.location.hostname === 'localhost'
        ? {
            apiKey: 'AIzaSyBQ-eijP-7hP5LBcWXol6H1GzsBbYr2OYU',
            authDomain: 'meinschwein-test.firebaseapp.com',
            projectId: 'meinschwein-test',
            storageBucket: 'meinschwein-test.appspot.com',
            messagingSenderId: '44518012008',
            appId: '1:44518012008:web:e3d931b52db9da5e6af7dd'
          }
        : {
            apiKey: 'AIzaSyB4IBOCDod3MICZRGK4csNaShmOUiXsNpE',
            authDomain: 'agromedia-meinschwein.firebaseapp.com',
            databaseURL: 'https://agromedia-meinschwein.firebaseio.com',
            projectId: 'agromedia-meinschwein',
            storageBucket: 'agromedia-meinschwein.appspot.com',
            messagingSenderId: '929058657223',
            appId: '1:929058657223:web:a88298e68b598d84c23fe2',
            measurementId: 'G-W95FYM76N9'
          }
    firebase.initializeApp(firebaseConfig)

    if (window.location.hostname === 'localhost') {
      console.log('Running on localhost. Google Analytics is disabled.')
    } else {
      firebase.analytics()
    }

    await mainStore.restoreCart()

    let unsubscriber: () => void

    userChanged(async (firebaseUser) => {
      if (firebaseUser) {
        const identity: Identity = {
          id: firebaseUser.uid,
          email: firebaseUser.email,
          name: firebaseUser.displayName,
          photoUrl: firebaseUser.photoURL
        }

        // Wait for display name and user to become available
        let user: User

        while (!identity.name || !user) {
          await new Promise((resolve) => setTimeout(resolve, 100))
          identity.name = getUser().displayName
          try {
            user = (
              await firestore()
                .collection('users')
                .withConverter(new UserConverter())
                .doc(identity.id)
                .get()
            ).data()
          } catch (ex) {
            // Ignored
          }
        }

        if (!user.name) {
          user.name = identity.name
          await user.reference
            .withConverter(new UserConverter())
            .set(user, { merge: true })
        }

        mainStore.setIdentity(identity)

        const userReference = firestore()
          .collection('users')
          .withConverter(new UserConverter())
          .doc(firebaseUser.uid)

        user = (await userReference.get()).data()
        mainStore.setUser(user)
        if (user.farm) {
          const farm = (
            await user.farm.reference.withConverter(new FarmConverter()).get()
          ).data()
          mainStore.setCurrentFarm(farm)
        }

        unsubscriber = userReference.onSnapshot((doc) =>
          store.commit('setUser', doc.data())
        )

        // Setup messaging
        // Initialize messaging
        if (firebase.messaging.isSupported()) {
          const messaging = firebase.messaging()
          try {
            messaging
              .getToken({
                vapidKey:
                  window.location.hostname === 'localhost'
                    ? 'BOuegowpzAy6syeZdPjSNnasnQq6Pwzm-t1-IqBDD8UBhyDchpgh75yVdIRApf6ATx1k0Ezn_nuxa7pLgOfGQek'
                    : 'BFmu38q9mQIv4aY3UZlx5GosEUr7B2uVAB07-d6uuJvjezKDmtXCDoyAJ9FVjq53QiNJPLi6iNcSA28eHeZZ_Ks'
              })
              .then(async (currentToken) => {
                if (currentToken) {
                  if (currentToken !== user.messagingToken) {
                    user.messagingToken = currentToken
                    await user.reference
                      .withConverter(new UserConverter())
                      .set(user, { merge: true })
                  }
                } else {
                  Notification.requestPermission()
                }
              })
          } catch (ex) {
            // Ignored
          }

          messaging.onMessage((payload) => {
            showNotification({
              title: payload.notification.title,
              text: payload.notification.body,
              timeout: 10
            })
          })
        }

        if (mainStore.initialized) {
          // Perform navigation only if state has been initialized

          let defaultRoute = 'home'
          if (user.role === 'admin' || user.role === 'farmer') {
            defaultRoute = 'admin/farm'
          }

          const targetName: string =
            (router.currentRoute.query.target as string) ?? defaultRoute
          router.push({ name: targetName })
        }
      } else {
        mainStore.setIdentity(null)
        mainStore.setUser(null)
        if (unsubscriber) {
          unsubscriber()
        }

        if (
          mainStore.initialized &&
          router.currentRoute.matched.some((m) => m.meta.requiresAuthenticated)
        ) {
          // Perform navigation only if state has been initialized and route requires authenticated user

          router.push({ name: 'landing' })
        }
      }

      if (!mainStore.initialized) {
        mainStore.init()
      }
    })
  }
}).$mount('#app')
