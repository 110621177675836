



































import { Component, Vue } from 'vue-property-decorator'
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
import Button from '@/components/Button.vue'
import Notification from '@/components/Notification.vue'
import CookieBanner from '@/components/CookieBanner.vue'
import PromotionBanner from '@/components/PromotionBanner.vue'
import { NotificationContent } from '@/models/NotificationContent'
import { onNotification } from '@/services/NotificationService'
import { mainStore } from '@/store'
import { mixins } from 'vue-class-component'
import UserMixin from '@/mixins/UserMixin.vue'
import { User } from './models/User'
import moment from 'moment'

@Component({
  components: {
    Navigation,
    Footer,
    Loading,
    Notification,
    CookieBanner,
    Button,
    PromotionBanner
  }
})
export default class App extends mixins(UserMixin) {
  notificationTimeout: any

  get initialized(): boolean {
    return mainStore.initialized
  }

  get user(): User {
    return mainStore.user
  }

  notificationContent: NotificationContent = {
    title: null,
    text: null,
    type: 'success',
    visible: false
  }

  created() {
    onNotification((content) => {
      this.notificationContent = content
      this.showNotification()
    })
  }

  showNotification() {
    this.notificationContent.visible = true
    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout)
    }

    if (this.notificationContent.timeout) {
      this.notificationTimeout = setTimeout(
        () => (this.notificationContent.visible = false),
        this.notificationContent.timeout * 1000
      )
    }
  }
}
