















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { NotificationContent } from '@/models/NotificationContent'

@Component({
  components: {}
})
export default class Notification extends Vue {
  @Prop()
  content: NotificationContent
}
