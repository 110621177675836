import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Identity } from '@/models/Identity'
import { User } from '@/models/User'
import { UserOrderItem } from '@/models/UserOrderItem'
import { firestore } from 'firebase/app'
import { ProductConverter } from '@/models/converters/ProductConverter'
import { Farm } from '@/models/Farm'
import { Product } from '@/models/Product'

@Module({ name: 'main' })
export default class Main extends VuexModule {
  identity: Identity = null
  user: User = null
  currentFarm: Farm = null
  farms: Farm[] = []
  products: Product[] = []

  initialized: boolean = false
  cart: { items: UserOrderItem[] } = {
    items: []
  }

  @Mutation
  setFarms(farms: Farm[]) {
    this.farms = farms
  }

  @Mutation
  setProducts(products: Product[]) {
    this.products = products
  }

  @Mutation
  setIdentity(identity: Identity) {
    this.identity = identity
  }

  @Mutation
  setUser(user: User) {
    this.user = user
  }

  @Mutation
  setCurrentFarm(farm: Farm) {
    this.currentFarm = farm
  }

  @Mutation
  init() {
    this.initialized = true
  }

  @Mutation
  addToCart(item: UserOrderItem) {
    this.cart.items = [...this.cart.items, item]
    synchronizeCart(this.cart.items)
  }

  @Mutation
  removeFromCart(item: UserOrderItem) {
    this.cart.items = this.cart.items.filter(
      (i) => i.product.id !== item.product.id
    )
    synchronizeCart(this.cart.items)
  }

  @Mutation
  clearCart() {
    this.cart = {
      items: []
    }
    localStorage.removeItem(orderItemsKey)
  }

  @Action
  async restoreCart() {
    const orderItems = localStorage.getItem(orderItemsKey)
    if (orderItems) {
      const items: any[] = JSON.parse(orderItems)

      for (const item of items) {
        const orderItem = new UserOrderItem()
        orderItem.amount = item.amount
        orderItem.params = item.params
        orderItem.displayParams = item.displayParams

        const product = (
          await firestore()
            .doc(item.productPath)
            .withConverter(new ProductConverter())
            .get()
        ).data()
        orderItem.product = product

        this.addToCart(orderItem)
      }
    }
  }

  @Action
  async initializeWait() {
    while (!this.initialized) {
      await new Promise((r) => setTimeout(r, 100))
    }
  }
}

const orderItemsKey = 'order_items'
const synchronizeCart = (items: UserOrderItem[]) => {
  let orderItems: LocalOrderItem[] = []
  for (const item of items) {
    orderItems = [
      ...orderItems,
      {
        amount: item.amount,
        params: item.params,
        displayParams: item.displayParams,
        ...(item.product ? { productPath: item.product.reference.path } : {})
      }
    ]
  }

  localStorage.setItem(orderItemsKey, JSON.stringify(orderItems))
}
