import Vue from 'vue'
import Vuex from 'vuex'

import Main from '@/store/main'
import { init, mainStore } from '@/store/store-provider'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    main: Main
  }
})

init(store)

export { store, mainStore }