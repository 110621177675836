import { Product } from '@/models/Product'
import { UserOrderItem } from '@/models/UserOrderItem'

export class UserOrderItemConverter implements firebase.firestore.FirestoreDataConverter<UserOrderItem> {
  toFirestore(item: UserOrderItem): firebase.firestore.DocumentData {
    return {
      product: item.product.reference,
      amount: item.amount,
      ...item.params
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    options: firebase.firestore.SnapshotOptions) {
    const data = snapshot.data(options)
    const item = new UserOrderItem(snapshot.ref)
    item.product = new Product(data.product)
    item.amount = data.amount
    item.state = data.state
    const { product, amount, state, ...params } = data
    item.params = params
    return item
  }
}