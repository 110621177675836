import { Model } from '@/models/Model'

class UserMessage extends Model {
  title: string
  body: string
  action: string
  createdAt: Date
  read: boolean

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { UserMessage }