abstract class Model {
  internalId: string | undefined

  get id(): string | undefined {
    return this.reference?.id ?? this.internalId
  }

  set id(value: string) {
    this.internalId = value
  }

  get isNew(): boolean {
    return this.id == null
  }

  constructor(public reference?: firebase.firestore.DocumentReference) {}

  populate(): Promise<void> {
    return Promise.resolve()
  }
}

export { Model }
