































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class CookieBanner extends Vue {
  hidden: boolean = false

  get isVisible() {
    if (localStorage.getItem('cookie_consent_given')) {
      return false
    }
    return !this.hidden
  }

  giveConsent() {
    localStorage.setItem('cookie_consent_given', 'true')
    this.hidden = true
  }
}
