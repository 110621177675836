import EventBus from '@/services/EventBus'
import { NotificationContent } from '@/models/NotificationContent'

const showNotification = (content: NotificationContent) => {
  EventBus.$emit('notification', { ...content, visible: true })
}

const onNotification = (handler: (content: NotificationContent) => void) => {
  EventBus.$on('notification', handler)
}

export { showNotification, onNotification }