



























































import { Component, Vue, Emit, Model, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Dialog extends Vue {
  @Prop({ default: false })
  closeOnClickOutside!: boolean

  @Model('change')
  value: boolean

  @Emit('change')
  updateValue(value: boolean) {
    return value
  }
}
