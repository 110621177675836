
















import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading
  }
})
export default class Button extends Vue {
  @Prop()
  type: string

  @Prop()
  disabled: boolean

  @Prop()
  loading: boolean

  spinnerClass: string = ''

  @Emit()
  click() {
    // placeholder
  }

  performClick() {
    if (this.disabled || this.loading) {
      return
    }
    this.click()
  }

  get buttonType(): string {
    return this.disabled || this.loading ? 'button' : this.type
  }

  get element(): Element {
    return this.$el
  }

  getSpinnerClass(): string {
    if (this.$el) {
      if (
        this.$el.className.includes('red') ||
        this.$el.className.includes('brand')
      ) {
        return 'bg-white'
      } else {
        return 'bg-gray-700'
      }
    }
    return ''
  }

  mounted() {
    this.spinnerClass = this.getSpinnerClass()
  }
}
