import { Model } from '@/models/Model'

class FarmCamera extends Model {
  caption: string
  active: boolean
  index: number

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { FarmCamera }
