import { UserMessage } from '@/models/UserMessage'

export class UserMessageConverter implements firebase.firestore.FirestoreDataConverter<UserMessage> {
  toFirestore(message: UserMessage): firebase.firestore.DocumentData {
    return {
      read: message.read
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    options: firebase.firestore.SnapshotOptions) {
    const data = snapshot.data(options)
    const message = new UserMessage(snapshot.ref)
    message.title = data.title
    message.body = data.body
    message.action = data.action ?? ''
    message.createdAt = data.created_at.toDate()
    message.read = data.read

    return message
  }
}