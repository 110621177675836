





























































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Navigation from '@/components/Navigation.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Cam from '@/components/Cam.vue'

@Component({
  components: {
    Navigation,
    Header,
    Footer,
    Cam
  }
})
export default class Landing extends Vue { }
