




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class Cam extends Vue {
  @Prop()
  src: string

  interval?: any = null
  timestamp?: string = new Date().toISOString()

  get url(): string {
    return `${this.src}?timestamp=${this.timestamp}`
  }

  created() {
    this.interval = setInterval(() => {
      this.timestamp = new Date().toISOString()
    }, 3000)
  }

  destroyed() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
