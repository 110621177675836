
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mainStore } from '@/store'
import { User } from '@/models/User'

@Component
export default class UserMixin extends Vue {
  get mainStore() {
    return mainStore
  }

  get user(): User {
    return this.mainStore.user
  }

  get isAdmin(): boolean {
    return this.mainStore.user.role === 'admin'
  }

  get isFarmer(): boolean {
    return this.mainStore.user.role === 'farmer'
  }
}
