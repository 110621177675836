import moment from 'moment'

moment.locale('de')

const weekdays: { [id: number]: string } = {
  1: 'Mo',
  2: 'Di',
  3: 'Mi',
  4: 'Do',
  5: 'Fr',
  6: 'Sa',
  7: 'So'
}

const formatDate = (date: Date, format?: string) => moment(date).format(format ?? 'LL')
const formatDateTime = (date: Date) => moment(date).format('DD.MM.YY, HH:mm')

const getDaysUntil = (date: Date) => moment(date).diff(
  moment().startOf('day'),
  'days'
)

const getFromNow = (date: Date) => moment(date).fromNow()

const mapWeekdays = (isoWeekdays: number[]) => {
  return isoWeekdays.map((i) => weekdays[i])
}

export { formatDate, formatDateTime, mapWeekdays, getDaysUntil, getFromNow }