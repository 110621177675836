import { Model } from '@/models/Model'

class FarmPeriod extends Model {
  settingDay: Date = new Date()
  slaughterDay: Date = new Date()
  totalCapacity?: number = null
  availableCapacity: number = 0

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { FarmPeriod }
