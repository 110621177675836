import { FarmCamera } from '@/models/FarmCamera'
import { Farm } from '@/models/Farm'

export class FarmConverter
  implements firebase.firestore.FirestoreDataConverter<Farm> {
  toFirestore(farm: Farm): firebase.firestore.DocumentData {
    return {
      name: farm.name
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const farm = new Farm(snapshot.ref)
    farm.name = data.name
    farm.slug = data.slug
    farm.street = data.street
    farm.city = data.city
    farm.zip = data.zip
    farm.capacity = data.capacity
    farm.sectors = data.sectors
    farm.photoUrl = data.photo_url
    if (data.short_description) {
      farm.shortDescription = data.short_description
    }
    farm.underConstruction = data.under_construction ?? false
    farm.description = data.description
    farm.listed = data.listed
    farm.detailed = data.detailed
    farm.hasShop = data.has_shop
    farm.imageSources = data.image_sources || []
    farm.ip = data.ip
    farm.commissionPercentage = data.commission_percentage
    farm.assignSectors = data.assign_sectors
    if (data.position) {
      farm.lat = data.position.latitude
      farm.long = data.position.longitude
    }

    if (data.cameras) {
      farm.cameras = data.cameras.map((cam: any) => new FarmCamera(cam))
    } else {
      farm.cameras = []
    }

    if (data.services) {
      farm.services = data.services
      farm.services.sort()
    } else {
      farm.services = []
    }
    return farm
  }
}
