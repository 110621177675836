import { User } from '@/models/User'
import { Model } from '@/models/Model'
import {
  BasicFeedingType,
  CuttingVariant,
  DeliveryMethod
} from '@/models/Types'
import { UserOrderItem } from '@/models/UserOrderItem'
import { UserOrderItemConverter } from '@/models/converters/UserOrderItemConverter'
import { FarmPeriodSector } from '@/models/FarmPeriodSector'

// User assignment on period
class FarmPeriodAssignment extends Model {
  createdAt: Date
  user: User
  orderItem: UserOrderItem
  basicFeeding: BasicFeedingType
  cutting: {
    variant: CuttingVariant
    deliveryMethod: DeliveryMethod
    address: {
      formattedAddress: string
      lat: number
      long: number
    }
    orderItem: UserOrderItem | 'cash'
    comment: string
    sausages: Array<{ name: string; weight: number }>
  } = {
    variant: 'fine_vacuum_packed',
    deliveryMethod: 'pickup',
    address: null,
    orderItem: null,
    comment: '',
    sausages: []
  }
  sector?: FarmPeriodSector

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }

  async populate() {
    this.orderItem = (
      await this.orderItem.reference
        .withConverter(new UserOrderItemConverter())
        .get()
    ).data()

    await this.orderItem.populate()
  }
}

export { FarmPeriodAssignment }
