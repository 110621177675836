import firebase from 'firebase/app'

const signIn = async (email: string, password: string, isPersistent: boolean): Promise<firebase.User | null> => {
  try {
    const persistence = isPersistent ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION
    await firebase.auth().setPersistence(persistence)
    const credential = await
      firebase.auth()
        .signInWithEmailAndPassword(email, password)
    return credential.user
  } catch (error) {
    throw new Error(buildErrorMessage(error))
  }
}

const getCredential = async (email: string, password: string) => {
  return firebase.auth.EmailAuthProvider.credential(email, password)
}

const signUp = async (email: string, password: string): Promise<firebase.User | null> => {
  try {
    const credential = await firebase.auth()
      .createUserWithEmailAndPassword(email, password)
    return credential.user
  } catch (error) {
    throw new Error(buildErrorMessage(error))
  }
}

const resetPassword = async (email: string): Promise<void> => {
  try {
    await firebase.auth()
      .sendPasswordResetEmail(email)
  } catch (error) {
    throw new Error(buildErrorMessage(error))
  }
}

const signOut = async (): Promise<void> => {
  try {
    await firebase.auth()
      .signOut()
  } catch (error) {
    throw new Error(buildErrorMessage(error))
  }
}

const userChanged = (handler: (user: firebase.User | null) => void): firebase.Unsubscribe => {
  return firebase.auth().onAuthStateChanged(handler)
}

const getUser = (): firebase.User | null => {
  return firebase.auth().currentUser
}

const buildErrorMessage = (error: any) => {
  switch (error.code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password': {
      return 'E-Mail Adresse oder Passwort falsch. Bitte versuche es erneut.'
    }
    case 'auth/user-disabled': {
      return 'Dein Konto wurde gesperrt. Bitte wende dich an den Administrator.'
    }
    case 'auth/invalid-email': {
      return 'Die eingebene E-Mail Adresse ist nicht gültig.'
    }
    case 'auth/email-already-in-use': {
      return 'Diese E-Mail Adresse wird bereits verwendet.'
    }
    case 'auth/weak-password': {
      return 'Das angegebene Passwort ist leider zu schwach.'
    }
    default: {
      return error.code
    }
  }
}

export {
  signIn,
  signUp,
  signOut,
  resetPassword,
  getUser,
  getCredential,
  userChanged
}