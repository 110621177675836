import { FarmCamera } from '@/models/FarmCamera'
import { Model } from '@/models/Model'

class Farm extends Model {
  street: string
  capacity: number
  sectors: number
  city: string
  lat: number
  long: number
  name: string
  slug: string
  zip: number
  photoUrl: string
  shortDescription: string
  description: string
  services: string[]
  cameras: FarmCamera[]
  listed: boolean
  detailed: boolean
  hasShop: boolean
  underConstruction: boolean
  imageSources: string[]
  ip: string
  commissionPercentage: number
  assignSectors: boolean
  nextSettingDay: Date
  nextSlaughterDay: Date
  soldOut: boolean
  distance: number

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { Farm }
