import { Model } from '@/models/Model'
import { Product } from '@/models/Product'
import { UserOrderItemState } from '@/models/Types'
import { formatMoney } from '@/services/NumberService'
import { firestore } from 'firebase/app'
import { FarmPeriodConverter } from '@/models/converters/FarmPeriodConverter'
import { ProductConverter } from '@/models/converters/ProductConverter'

class UserOrderItem extends Model {
  product: Product
  amount: number
  state: UserOrderItemState
  params: any = {}
  displayParams: any = {}

  get calculatedAmount(): number {
    // Returns the calculated amount based on the product type
    // If the product type is "pig", then the product's amount is used
    // If the product type is "credits" or else, then either the item's
    // amount is used if the item id is available
    // (because the amount is tied to the item during order creation)
    // or the amount from "params" is used (params.amount is set
    // during order process and passed to the createOrder function)
    return this.product.type === 'pig'
      ? this.product.amount
      : this.id
      ? this.amount
      : this.params.amount
  }

  get formattedAmount(): string {
    return formatMoney(this.calculatedAmount)
  }

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }

  async populate() {
    this.product = (
      await this.product.reference.withConverter(new ProductConverter()).get()
    ).data()

    if (this.params.assignment) {
      const period = (
        await firestore()
          .collection('farms')
          .doc(this.params.farm_id)
          .collection('periods')
          .doc(this.params.period_id)
          .withConverter(new FarmPeriodConverter())
          .get()
      ).data()

      this.displayParams.setting_day = period.settingDay
      this.displayParams.slaughter_day = period.slaughterDay
    }
  }
}

export { UserOrderItem }
