import { Product } from '@/models/Product'

export class ProductConverter implements firebase.firestore.FirestoreDataConverter<Product> {
  toFirestore(product: Product): firebase.firestore.DocumentData {
    return {
      name: product.name,
      description: product.description,
      short_description: product.shortDescription,
      amount: product.amount,
      image_url: product.imageUrl,
      slug: product.slug,
      type: product.type,
      meta: product.meta
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    options: firebase.firestore.SnapshotOptions) {
    const data = snapshot.data(options)
    const product = new Product(snapshot.ref)
    product.name = data.name
    product.description = data.description
    product.shortDescription = data.short_description
    product.amount = data.amount
    product.type = data.type
    product.slug = data.slug
    product.imageUrl = data.image_url
    product.meta = data.meta
    return product
  }
}