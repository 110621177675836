
























import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import Button from '@/components/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class Dropdown extends Vue {
  @Prop()
  text: string

  @Prop()
  open: boolean = false

  @Emit()
  close() {
    // placeholder
  }

  mounted() {
    document.addEventListener('click', (evt) => {
      evt.stopPropagation()
      if (!this.$el.contains(evt.target as any)) {
        this.close()
      }
    })
  }
}
