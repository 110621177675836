import { FarmPeriod } from '@/models/FarmPeriod'

export class FarmPeriodConverter
  implements firebase.firestore.FirestoreDataConverter<FarmPeriod> {
  toFirestore(period: FarmPeriod): firebase.firestore.DocumentData {
    return {
      setting_day: period.settingDay,
      slaughter_day: period.slaughterDay,
      available_capacity: period.availableCapacity ?? 0,
      total_capacity: period.totalCapacity ?? 0
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >,
    options: firebase.firestore.SnapshotOptions
  ) {
    const data = snapshot.data(options)
    const period = new FarmPeriod(snapshot.ref)
    period.settingDay = data.setting_day.toDate()
    period.slaughterDay = data.slaughter_day.toDate()
    period.availableCapacity = data.available_capacity ?? 0
    period.totalCapacity = data.total_capacity ?? 0
    return period
  }
}
