var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PromotionBanner'),_c('div',{staticClass:"flex flex-col min-h-screen"},[(
        _vm.$route.name !== 'landing' &&
        _vm.$route.name !== 'sign-in' &&
        _vm.$route.name !== 'sign-up' &&
        _vm.$route.name !== 'forgot-password'
      )?_c('Navigation',{staticClass:"bg-brand-500",attrs:{"id":"app"}}):_vm._e(),_c('div',{staticClass:"z-0 flex flex-col flex-1 h-full bg-brand-100"},[_c('router-view',{staticClass:"flex-1"})],1)],1),_c('CookieBanner'),_c('Notification',{attrs:{"content":_vm.notificationContent}}),(
      _vm.$route.name !== 'sign-in' &&
      _vm.$route.name !== 'sign-up' &&
      _vm.$route.name !== 'forgot-password'
    )?_c('Footer'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }