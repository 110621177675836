import { FarmPeriodAssignment } from '@/models/FarmPeriodAssignment'
import { Model } from '@/models/Model'
import { UserRole } from '@/models/Types'
import { Farm } from '@/models/Farm'
import { formatMoney } from '@/services/NumberService'

class User extends Model {
  email: string
  name: string
  photoUrl: string
  credits: number
  phone: string
  role: UserRole = 'customer'
  messagingToken: string
  farm: Farm = null
  activeAssignment: FarmPeriodAssignment = null
  meta: any

  get abbreviatedName(): string {
    const nameParts = this.name.split(' ')
    if (nameParts.length > 1) {
      return `${nameParts[0]} ${nameParts[1][0]}.`
    }
    return this.name
  }

  get formattedCredits(): string {
    return formatMoney(this.credits)
  }

  get formattedNegativeCredits(): string {
    return formatMoney(this.credits * -1)
  }

  assignments: FarmPeriodAssignment[] = []

  get assignment() {
    return (
      this.activeAssignment ??
      (this.assignments.length > 0 ? this.assignments[0] : null)
    )
  }

  set assignment(value: FarmPeriodAssignment) {
    this.activeAssignment = value
  }

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { User }
