import { Model } from '@/models/Model'
import { ProductType } from '@/models/Types'
import { formatMoney } from '@/services/NumberService'

class Product extends Model {
  name: string
  shortDescription: string
  description: string
  imageUrl: string
  amount: number
  get formattedAmount(): string {
    return formatMoney(this.amount)
  }

  slug: string
  type: ProductType
  meta: any

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
  }
}

export { Product }
